(function swiperControlsWrapper() {
	'use strict';

	function updateSwiperControls() {
		const setupSwiper = (swiperContainer) => {
			const slideElements =
				swiperContainer.querySelectorAll('.swiper-slide');
			const pagination =
				swiperContainer.querySelector('.swiper-pagination');
			const nextArrow = swiperContainer.querySelector(
				'.swiper-button-next',
			);
			const prevArrow = swiperContainer.querySelector(
				'.swiper-button-prev',
			);

			const totalSlides = slideElements.length;

			if (totalSlides <= 1) {
				if (pagination) pagination.classList.add('swiper-hidden');
				if (nextArrow) nextArrow.classList.add('swiper-hidden');
				if (prevArrow) prevArrow.classList.add('swiper-hidden');
			} else {
				if (pagination) pagination.classList.remove('swiper-hidden');
				if (nextArrow) nextArrow.classList.remove('swiper-hidden');
				if (prevArrow) prevArrow.classList.remove('swiper-hidden');
			}
		};

		const swiperContainers = document.querySelectorAll('.swiper-container');
		swiperContainers.forEach((container) => setupSwiper(container));
	}

	document.addEventListener('DOMContentLoaded', updateSwiperControls);
	window.addEventListener('load', updateSwiperControls);
})();
